.mainContainer{
  height: 100% !important;
  overflow: hidden;
}

.App-header {
 color: #ffff;
 padding-top: 20px !important;
 
}

.logo {
  float: left;
}

.logo h1 {
  font-family: 'Agustina', sans-serif;
  font-size: 50px !important;
}

.Menu {
  float: right;
  
}

.Menu ul {
  list-style-type: none;
  
}

.Menu li {
  display: inline;
  margin: 10px !important;
}

.Menu a {
  text-decoration: none ;
  font-size: 30px !important;
  color: white;
  margin: 15px !important;
}

.Menu a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: rgb(250, 114, 64);
  -moz-text-decoration-color: rgb(250, 114, 64);
  text-decoration-thickness: 3px;
  transition: all 0.5s ease-in;
}

.Menu-icon{
  display: none;
}

.Menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.Menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.fas {
  cursor: pointer;
}


@media screen and (max-width: 500px){
  body{
    padding-left: 10px !important ;
    padding-right: 10px !important ;
  }
  
  .logo{
    padding-left: 15px !important;
  }
  .logo h1 {
    font-size: 30px !important;
  }
  .Menu-icon{
    display: inline;
    padding-right: 15px !important;
    float: right;
    font-size: 30px;
  }
  .Menu{
    flex-direction: column;
    font-weight: 600;
    width: 95%;
    height: 40% !important;
    display: flex !important;
    position: absolute;
    background-color: #171c28f6;    
    top: 100px !important;
    z-index: 2;
    
  }

  .Menu li {
    flex: 1;
    padding-left: 15px !important;
    padding-top: 3px !important;
    display: flex;
    justify-content: left !important;
    align-items: left !important;
    height: 40px !important;
  }

  .Menu.close {
    left: -100%;
  }
 
  
}

