.Salute {
  padding-top: 50px !important;
  margin: 0 !important;
  overflow: hidden;
  display: block;
  width: 100% !important;
  height: 700px !important;
}

.waveSalute img{
  width: 30px !important;
  height: 30px !important;
}

.SaluteIntro{
  float: left;
  width: 50%;
  overflow: hidden;
}

.SaluteImage{
  overflow: hidden;
}

.Salute h1 {
  color: white !important;
  font-size: 70px !important;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  letter-spacing: 2px !important;
}

.Salute p {
  font-size: 40px !important;
  color: white;
  padding-top: 20px !important;
  text-align: justify !important;
}

.waveSalute img {
  padding-left: 20px !important;
  height: 55px !important;
  width: 55px !important;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: salute;
  display: inline-block;
  transform-origin: 70% 70%;
}
.Knowledge {
  padding-top: 10px;
  margin: 0 ;
  overflow: hidden;
  height: 600px;
}

#KnowledgeImage{
  float: left;
  width: 50%;
}
#KnowledgeImage img {
    width: 70%;
}

.KnowledgeIntro{
  overflow: hidden;
  width: 50%;
}
.KnowledgeIntro h1 {
  color: white ;
  font-size: 70px ;
  padding-bottom: 55px !important;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  letter-spacing: 2px;
}

.KnowledgeIntro p {
  font-size: 20px !important;
  color: white;
}

.KnowledgeIntro p > span {
  padding-right: 15px !important;
  animation: finger 2s 2;
  animation-direction: alternate;
  display: inline-block;
}

@media screen and (max-width: 500px) {
  
  .Knowledge{
    width: 100%;
    
    padding-left: 10px !important;
  }
  .KnowledgeIntro {
    width: 95% !important;
  }

  #KnowledgeImage{
    display: none;
  }

  .KnowledgeIntro h1 {
    font-size: 34px !important;
    padding-bottom: 10px !important;
    text-align: center;
  }

  .KnowledgeIntro p {
    font-size: 20px !important;
    color: white;
    text-align: center;
  }

  .Knowledge p > span {
    padding-right: 2px !important;
  }

  .Salute{
    padding-top: 30px !important;
    height: 650px !important;
  }
  .SaluteIntro{   
    float: none !important;
    width: 95% !important;
    padding-left: 10px !important;
    padding-bottom: 40px !important;
  }
  .Salute p {
    font-size: 20px !important;
    color: white;
    padding-top: 20px !important;
  }
  .Salute h1 {
    font-size: 30px !important;
    text-align: center;
  }

  .waveSalute img{
    height: 30px !important;
    width: 30px !important;
    padding-left: 10px !important;
  }
  .SocialMedias {
    text-align: center !important;
  }
}
.SocialMedias {
  padding-top: 40px !important;
}

.SocialMedias a{
  padding: 10px !important;
  
}
.SocialMedias i:hover{
  background-color: black !important;
}

.giti{
  font-size: 30px !important;
  padding: 10px !important;
  text-align: center !important;
  background-color: #333 !important;
  border-radius: 2.6rem !important;
  width: 2.1rem;
  height: 1.9rem;
  color: white;
}
.linkedini{
  font-size: 30px !important;
  padding: 10px !important;
  background-color: #0e76a8 !important;
  border-radius: 2rem !important;
  width: 2.1rem;
  height: 1.9rem;
  margin: 0 5px 10px;
  text-align: center !important;
  color: white;
}
.instagrami{
  font-size: 30px !important;
  padding: 10px !important;
  background-color: #fca326 !important;
  border-radius: 2.6rem !important;
  width: 2.1rem;
  height: 1.9rem;
  margin: 0 5px 10px;
  text-align: center !important;
  color: white;
}
.emaili{
  font-size: 30px !important;
  padding: 10px !important;
  color: white;
  background-color: #ea4335 !important;
  border-radius: 2.6rem !important;
  width: 2.1rem;
  height: 1.9rem;
  margin: 0 5px 10px;
  text-align: center !important;
}



@keyframes salute {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes finger {
  0%   { left: 0px;}
  25%   { left: 5px;}
  50%   { left: 0px;}
  75%   { left: 5px;}
}

